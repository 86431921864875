<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <v-container>
        <h1>
          <span>Договоры</span>
          <div>
            <v-btn @click="$router.push('/contractEdit')">
              Новый договор
            </v-btn>
          </div>
        </h1>

        <Register :config="regConfig" ref="registerComponent"></Register>

      </v-container>

    </transition>
  </div>
</template>
<script>

import Register from '../register/Register.vue';
import {
  customerContractsRegisterConfig,
  supplierContractsRegisterConfig
} from "@/components/register/RegisterConfigs";
import {
  generateFormalEmployeesRegisterConfig,
  generateOfferEmployeesRegisterConfig
} from "@/modules/SharedFunctions";

export default {
  name: 'ContractsComponent',
  props: {},
  data() {
    return {
      offerExperts: [],
      formalExpers: [],
      regConfig: {
        tabs: [
          {
            title: 'C заказчиками',
            config: customerContractsRegisterConfig
          },
          {
            title: 'C подрядчиками',
            config: supplierContractsRegisterConfig
          }
        ]
      }
    }
  },
  async beforeMount() {
    const formalEmployeesRegisterConfig = await generateFormalEmployeesRegisterConfig()
    const offerEmployeesRegisterConfig = await generateOfferEmployeesRegisterConfig()
    this.regConfig.tabs[0].config.filters
        .find(e => {
          return e.path === 'offerExpert.id'
        })
        .regConfig = offerEmployeesRegisterConfig
    this.regConfig.tabs[0].config.filters
        .find(e => {
          return e.path === 'formalExpert.id'
        })
        .regConfig = formalEmployeesRegisterConfig
    this.regConfig.tabs[1].config.filters
        .find(e => {
          return e.path === 'offerExpert.id'
        })
        .regConfig = offerEmployeesRegisterConfig
    this.regConfig.tabs[1].config.filters
        .find(e => {
          return e.path === 'formalExpert.id'
        })
        .regConfig = formalEmployeesRegisterConfig
    this.apiLoaded = true
  },
  components: {Register},
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>
